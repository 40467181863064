const attachmentList = require("./templates/attachment_list.hbs");
const rating = require("./templates/rating.hbs");
const searchResults = require("./templates/search_results.hbs");

const debounce = require("lodash.debounce");
const isEmpty = require("lodash.isempty");

var saveDropdownMenu = false;

document.addEventListener("click", (event) => {
  if (event.target.matches(".notification .delete")) {
    event.target.parentNode.parentNode.removeChild(event.target.parentNode);
  } else if (event.target.matches(".navbar-burger")) {
    event.target.classList.toggle("is-active");
    document
      .getElementById(event.target.dataset.target)
      .classList.toggle("is-active");
  } else if (event.target.matches(".dropdown-item")) {
    var dropdownMenu = document.getElementById("screenings-dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.remove();
    }
  } else {
    const closest = event.target.closest(".input-rating .rating .icon");
    if (closest) {
      const value = parseInt(closest.dataset.ratingValue);
      document.getElementById("review_rating").value = value;
      closest.parentNode.parentNode.innerHTML = rating({
        filled: [...Array(value + 1).keys()],
        unfilled: [...Array(5 - (value + 1)).keys()].map(
          (e) => e + (value + 1)
        ),
      });
    }
  }
});

document.addEventListener("change", (event) => {
  if (event.target.matches("#attachments input[type=file]")) {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      document
        .getElementById("attachments-list-root")
        .classList.remove("is-hidden");
      const attachmentsList = document.querySelector(
        "#attachments-list-root .panel"
      );
      attachmentsList.innerHTML = attachmentList({
        files: Array.from(fileInput.files).map((file) => file.name),
      });
    } else {
      document
        .getElementById("attachments-list-root")
        .classList.add("is-hidden");
    }
  }
});

document.addEventListener("turbolinks:load", () => {
  const ratingInputs = document.getElementsByClassName("input-rating");
  for (const input of ratingInputs) {
    input.innerHTML = rating({ unfilled: [0, 1, 2, 3, 4] });
  }
});

var debouncedFetch = debounce((e) => {
  if (e.target.value) {
    fetch("api/screenings/?name=" + encodeURIComponent(e.target.value)).then(
      (res) =>
        res.json().then((data) => {
          var dropdownMenu = document.getElementById(
            "screenings-dropdown-menu"
          );
          if (dropdownMenu) {
            dropdownMenu.remove();
          }
          var screeningsAutocompleteDropdown = document.getElementById(
            "screenings-autocomplete-dropdown"
          );

          if (!isEmpty(data)) {
            screeningsAutocompleteDropdown.innerHTML += searchResults({
              items: data,
            });

            var newDropdownMenu = document.getElementById(
              "screenings-dropdown-menu"
            );
            newDropdownMenu.addEventListener("mouseover", (e) => {
              saveDropdownMenu = true;
            });

            newDropdownMenu.addEventListener("mouseout", (e) => {
              saveDropdownMenu = false;
            });
          }

          document.getElementById("search-bar").value = e.target.value;
          document.getElementById("search-bar").focus();
        })
    );
  } else {
    saveDropdownMenu = false;
  }
}, 200);

document.addEventListener("input", (e) => {
  if (e.target.matches("#search-bar")) {
    debouncedFetch(e);
  }
});

document.addEventListener("focusout", (e) => {
  if (e.target.matches("#search-bar") && !saveDropdownMenu) {
    var dropdownMenu = document.getElementById("screenings-dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.remove();
    }
  }
});
