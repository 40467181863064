var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <span class=\"icon has-text-warning\" data-rating-value=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\">\n    <i class=\"fas fa-star\"></i>\n  </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <span class=\"icon has-text-warning\" data-rating-value=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\">\n    <i class=\"far fa-star\"></i>\n  </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <span class=\"icon\" data-rating-value=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\">\n    <i class=\"far fa-star\"></i>\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"rating\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"filled") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"hovered") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"unfilled") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "</p>\n";
},"useData":true});